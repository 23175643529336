import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import {PARTNER_I_PROXY_ONLINE_ROUTE, SIGN_UP_ROUTE} from '../../const/routes.const';
import {
  I_PROXY_ONLINE_DISCOUNT,
  I_PROXY_ONLINE_NAME, I_PROXY_ONLINE_PROMO_CODE
} from '../../const/parnters.const';
import PartnerLayout from '../../components/Partners/PartnerLayout';
import iproxy_mobile from '../../assets/images/partners/iproxy_mobile.png';
import iproxy_mobile2x from '../../assets/images/partners/iproxy_mobile_2x.png';
import iproxy_desktop from '../../assets/images/partners/iproxy_desktop.png';
import iproxy_desktop2x from '../../assets/images/partners/iproxy_desktop_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlueSpan from '../../components/common/typography/BlueSpan';
import PartnerDiscount from '../../components/Partners/PartnerDiscount';
import LinkWrapper from '../../components/common/LinkWrapper';

const IProxyOnlinePage = (): React.ReactElement => {
  return (
    <PartnerLayout
      imgDesktop={
        <ImageContainer src={iproxy_desktop} srcSet={{ _2x: iproxy_desktop2x }} />
      }
      imgMobile={<ImageContainer src={iproxy_mobile} srcSet={{ _2x: iproxy_mobile2x }} />}
      title={I_PROXY_ONLINE_NAME}
      href={PARTNER_I_PROXY_ONLINE_ROUTE}
      description={
        <>
          <p>
            <Trans>iProxy.online allows you to build your own private mobile proxies using an Android app in any country of the world in just a couple of minutes.</Trans>
          </p>
          <ul>
            <li><Trans>HTTP/SOCKS5</Trans></li>
            <li><Trans>IP change and rotation</Trans></li>
            <li><Trans>API</Trans></li>
            <li><Trans>DNS change</Trans></li>
            <li><Trans>High speed</Trans></li>
            <li><Trans>A Telegram bot with notification about IP change, uptime, and commands to change the IP address.</Trans></li>
          </ul>
          <p><Trans>No coding skills required. With iProxy anyone can set up mobile proxies for the best price on the market.</Trans></p>
          <h3>
            <Trans i18nKey="Offer for Octo Browser users">
              Offer for <BlueSpan>Octo Browser users</BlueSpan>
            </Trans>
          </h3>
          <p>
            <Trans i18nKey="This offer is valid for Octo Browser users only. Create an account to claim your discount. If you already use Octo Browser, go to the partner's website, sign up, and claim your offer.">
              This offer is valid for Octo Browser users only. <LinkWrapper isNative external url={SIGN_UP_ROUTE}>
              Create an account</LinkWrapper> to claim your discount. If you already use Octo Browser, go to the partner's website, sign up, and
              claim your offer.
            </Trans>
          </p>
          <PartnerDiscount
            discount={I_PROXY_ONLINE_DISCOUNT}
            href={PARTNER_I_PROXY_ONLINE_ROUTE}
            description={
              <Trans
                i18nKey="partner.i.proxy.online.promo.code.discount"
                tOptions={{ discount: I_PROXY_ONLINE_DISCOUNT, promoCode: I_PROXY_ONLINE_PROMO_CODE }}
              ><strong>{I_PROXY_ONLINE_PROMO_CODE}</strong>.
              </Trans>
            }
          />
          <h3>
            <Trans>iProxy.online key solutions and advantages:</Trans>
          </h3>
          <p><Trans>We support the most popular proxy types: HTTP and SOCKS5.</Trans></p>
          <p><Trans>Private and anonymous mobile proxies that you create and use yourself.</Trans></p>
          <p><Trans>Set up residential proxies with a phone connected to Wi-Fi. Add a smart plug to reboot the router, and you will get residential proxies with the IP change function.</Trans></p>
        </>
      }
    />
  );
};

export default IProxyOnlinePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["iProxyOnlinePage", "translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
